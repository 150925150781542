"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.pick = exports.omit = exports.toggleItem = exports.union = exports.intersection = exports.complement = exports.removeEmpty = exports.unique = exports.flat = exports.groupBy = exports.ensureArray = void 0;
function ensureArray(maybeArray) {
    return Array.isArray(maybeArray)
        ? maybeArray
        : (maybeArray === undefined || maybeArray === null)
            ? []
            : [maybeArray];
}
exports.ensureArray = ensureArray;
function groupBy(array, groupSelector) {
    if (groupSelector === void 0) { groupSelector = function (x) { return String(x); }; }
    return array.reduce(function (result, item) {
        var group = groupSelector(item);
        if (result[group]) {
            result[group].push(item);
        }
        else {
            result[group] = [item];
        }
        return result;
    }, {});
}
exports.groupBy = groupBy;
function flat(array) {
    return array.reduce(function (result, item) {
        result.push.apply(result, __spreadArray([], __read(item)));
        return result;
    }, []);
}
exports.flat = flat;
function unique(values) {
    return Array.from(new Set(values));
}
exports.unique = unique;
function removeEmpty(array) {
    return array.reduce(function (result, item) {
        if (item !== null && item !== undefined) {
            result.push(item);
        }
        return result;
    }, []);
}
exports.removeEmpty = removeEmpty;
/**
 * return an array of all elements that are in `a` but not in `b`
 */
function complement(a, b) {
    return a.filter(function (value) { return !b.includes(value); });
}
exports.complement = complement;
/**
 * return an array of all elements that are in `a` as well as in `b`
 */
function intersection(a, b) {
    return a.filter(function (value) { return b.includes(value); });
}
exports.intersection = intersection;
/**
 * return an array of all elements that are either in `a` or in `b`
 */
function union(a, b) {
    return __spreadArray(__spreadArray([], __read(a)), __read(complement(b, a)));
}
exports.union = union;
/**
 * return an array with `toggleItem` removed if it existed, or added if it didn't
 */
function toggleItem(array, toggledItem) {
    var e_1, _a;
    var result = [];
    var containsItem = false;
    try {
        for (var array_1 = __values(array), array_1_1 = array_1.next(); !array_1_1.done; array_1_1 = array_1.next()) {
            var item = array_1_1.value;
            if (item === toggledItem) {
                containsItem = true;
            }
            else {
                result.push(item);
            }
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (array_1_1 && !array_1_1.done && (_a = array_1.return)) _a.call(array_1);
        }
        finally { if (e_1) throw e_1.error; }
    }
    if (!containsItem) {
        result.push(toggledItem);
    }
    return result;
}
exports.toggleItem = toggleItem;
function omit(object) {
    var e_2, _a;
    var keys = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        keys[_i - 1] = arguments[_i];
    }
    var result = __assign({}, object);
    try {
        for (var keys_1 = __values(keys), keys_1_1 = keys_1.next(); !keys_1_1.done; keys_1_1 = keys_1.next()) {
            var key = keys_1_1.value;
            delete result[key];
        }
    }
    catch (e_2_1) { e_2 = { error: e_2_1 }; }
    finally {
        try {
            if (keys_1_1 && !keys_1_1.done && (_a = keys_1.return)) _a.call(keys_1);
        }
        finally { if (e_2) throw e_2.error; }
    }
    return result;
}
exports.omit = omit;
function pick(obj) {
    var keys = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        keys[_i - 1] = arguments[_i];
    }
    var entries = keys.filter(function (key) { return key in obj; }).map(function (key) { return ([key, obj[key]]); });
    return Object.fromEntries(entries);
}
exports.pick = pick;
