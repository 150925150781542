import { AppProps } from 'next/app';
import * as React from 'react';
import Head from 'next/head';
import nprogress from 'nprogress';
import Router from 'next/router';
import { I18nProvider } from '../components/i18n';
import 'nprogress/nprogress.css';
import './app.css';
import theme from '../lib/theme';
import { ThemeProvider, CssBaseline } from '@material-ui/core';
import { toSearchParams } from '@wooindex/common/searchParams';
import { FeatureFlagProvider } from '../components/FeatureFlags';
import GoogleTagManager from '../components/GoogleTagManager';
import { SWRConfiguration, SWRConfig } from 'swr';
import * as fetcher from '@wooindex/common/fetcher';
import { Dictionary } from '@wooindex/common/types';
import { UserProvider, useUser } from '../lib/user';
import { PageProps } from 'lib/createGetServerSideProps';

function SwrProvider ({ children }: React.PropsWithChildren<{}>) {
  const { refresh: refreshUser } = useUser();

  const swrFetcher = React.useCallback(async function swrFetcher<T = any> (url: string): Promise<T> {
    try {
      return await fetcher.get(url).json() as T;
    } catch (err) {
      if (err instanceof fetcher.HTTPError) {
        const { origin, pathname } = new URL(err.response.url);
        if (
          err.response.status === 401 &&
          typeof window !== 'undefined' &&
          window.location.origin === origin &&
          pathname !== '/api/user'
        ) {
          // user is not authenticated, make sure to refresh the authentication context
          refreshUser();
        }
      }
      throw err;
    }
  }, [refreshUser]);

  const swrConfig: SWRConfiguration<any, any, typeof swrFetcher> = {
    fetcher: swrFetcher,
    // the default was too agressive, override if you want
    focusThrottleInterval: 10 * 60 * 1000,
    dedupingInterval: 10 * 60 * 1000,
    shouldRetryOnError: false
  };

  return (
    <SWRConfig value={swrConfig}>
      {children}
    </SWRConfig>
  );
}

export interface AppPageProps extends PageProps {
  dictionary?: Dictionary
}

export default function MyApp (props: AppProps) {
  if (
    // dev-only check
    process.env.NODE_ENV === 'development' &&
    // ignore this for internal error pages
    !['/404', '/_error'].includes(props.router.pathname) &&
    !(props.pageProps as any).__gssp_called
  ) {
    throw new Error(`Page "${props.router.pathname}" must export getServerSideProps like:\n\n  export const getServerSideProps = createServerSideProps()`);
  }

  const { Component, pageProps, router } = props;
  const language = toSearchParams(router.query).get('lang');

  const { dictionary, user } = pageProps as AppPageProps;

  React.useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentElement!.removeChild(jssStyles);
    }

    nprogress.configure({ showSpinner: false, minimum: 0.3, trickleSpeed: 200 });
    let timeout: null | NodeJS.Timeout;
    const startProgess = () => {
      timeout = setTimeout(() => {
        timeout = null;
        nprogress.start();
      }, 100);
    };
    const stopProgess = () => {
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }
      nprogress.done();
    };
    Router.events.on('routeChangeStart', startProgess);
    Router.events.on('routeChangeComplete', stopProgess);
    Router.events.on('routeChangeError', stopProgess);
    return () => {
      nprogress.done();
      Router.events.off('routeChangeStart', startProgess);
      Router.events.off('routeChangeComplete', stopProgess);
      Router.events.off('routeChangeError', stopProgess);
    };
  }, []);

  return (
    <>
      <Head>
        <meta name='viewport' content='width=device-width, initial-scale=1' />
        <title>DataBravo</title>
      </Head>
      <GoogleTagManager>
        <UserProvider user={user}>
          <FeatureFlagProvider>
            <SwrProvider>
              <I18nProvider language={language} dictionary={dictionary}>
                <ThemeProvider theme={theme}>
                  <CssBaseline />
                  <Component {...pageProps} />
                </ThemeProvider>
              </I18nProvider>
            </SwrProvider>
          </FeatureFlagProvider>
        </UserProvider>
      </GoogleTagManager>
    </>
  );
}
