export type DataLayerValue = {
  event: 'pageview',
  page: string
} | {
  userId: number | null
}

declare global {
  interface Window {
    // This is being initialized in pages/_document.tsx
    dataLayer?: DataLayerValue[]
  }
}

export const pageview = (url: string) => {
  window.dataLayer?.push({
    event: 'pageview',
    page: url
  });
};
