export const RICH_BLACK = {
  100: '#F5F7FA',
  200: '#BEC2D0',
  300: '#737783',
  400: '#434751',
  500: '#181B25',
  900: '#0E111B'
};

export const LEMON = {
  100: '#F8FCD9',
  200: '#E5F679',
  500: '#D6F025',
  900: '#B8D20F'
};

export const SKY = {
  100: '#EBF7FE',
  200: '#B0DFFC',
  500: '#119FF7',
  900: '#077CC5'
};

export const ORANGE = {
  100: '#FFF0EB',
  200: '#FF875C',
  500: '#FF6A33',
  900: '#FF5A1F'
};

export const GREY = {
  100: '#F5F7FA',
  200: '#E6E9ED',
  300: '#CCD1D9',
  400: '#AAB2BD',
  500: '#656D78',
  900: '#353B48'
};

export const PURPLE = {
  100: '#F8F8FF',
  200: '#CCCAFF',
  300: '#A9A8FF',
  400: '#8385FF',
  500: '#6666FF',
  800: '#463CEF',
  900: '#2F00BD'
};

export const EMERALD = {
  100: '#E4F8E9',
  200: '#BDEDCA',
  300: '#90E1A7',
  400: '#57D683',
  500: '#00CC66',
  800: '#00B23E',
  900: '#006E0E'
};

export const DEEP_BLUE = {
  100: '#E1E3EA',
  200: '#B5BACC',
  300: '#868EAA',
  400: '#5A6589',
  500: '#3A4773',
  800: '#112658',
  900: '#00002D'
};

export const NAVY = {
  100: '#E2EBF3',
  300: '#1C334A',
  500: '#152637',
  900: '#111F2C'
};

export const MANGO = {
  100: '#FFF5EB',
  300: '#FF9D5C',
  500: '#FF7416',
  900: '#F56200'
};

export const BLUE = {
  100: '#E8F3FD',
  200: '#A6CEF5',
  300: '#63AAED',
  500: '#2286E6',
  700: '#1673CA',
  800: '#185EA1',
  900: '#124E8F'
};

export const LIGHT_BLUE = {
  100: '#E8F6FD',
  200: '#A6DBF5',
  300: '#63C0ED',
  500: '#22A5E5',
  900: '#1873A0'
};

export const RED = {
  100: '#FEE5E5',
  200: '#FB9999',
  300: '#F50000',
  500: '#F84C4C',
  900: '#AB0000'
};

export const YELLOW = {
  100: '#FFF6E5',
  200: '#FFDC99',
  300: '#FFA700',
  500: '#FFC14C',
  900: '#B27500'
};

export const GREEN = {
  100: '#E5F9E8',
  200: '#B2EEBC',
  300: '#00C622',
  500: '#4CD763',
  900: '#008A18'
};

export const CYAN = {
  100: '#EBFBFB',
  200: '#B1EDF0',
  300: '#76E0E4',
  500: '#3CD3D9',
  900: '#2A9498'
};

export const TEAL = {
  100: '#E7F4F1',
  200: '#A4D2C8',
  300: '#5FB09F',
  500: '#1C8F77',
  900: '#146453'
};

export const OLIVE = {
  100: '#E5EEEB',
  200: '#99BDB1',
  300: '#4C8B77',
  500: '#005A3E',
  900: '#003F2B'
};

export const WHITE = '#ffffff';

export const BLACK = '#000000';

export const SHADOW = '#e6e6e6';

export default {
  common: {
    black: BLACK,
    white: WHITE
  },
  shadow: SHADOW,
  richBlack: {
    main: RICH_BLACK[500],
    light: RICH_BLACK[100],
    dark: RICH_BLACK[900],
    contrastText: WHITE
  },
  lightBlue: {
    main: LIGHT_BLUE[500],
    light: LIGHT_BLUE[100],
    dark: LIGHT_BLUE[900],
    contrastText: WHITE
  },
  primary: {
    main: BLUE[500],
    light: BLUE[100],
    dark: BLUE[900],
    contrastText: WHITE
  },
  success: {
    main: GREEN[500],
    light: GREEN[100],
    dark: GREEN[900],
    contrastText: WHITE
  },
  warning: {
    main: YELLOW[500],
    light: YELLOW[100],
    dark: YELLOW[900],
    contrastText: WHITE
  },
  error: {
    main: RED[500],
    light: RED[100],
    dark: RED[900],
    contrastText: WHITE
  },
  navy: {
    main: NAVY[500],
    light: NAVY[100],
    dark: NAVY[900],
    contrastText: WHITE
  },
  sky: {
    main: SKY[500],
    light: SKY[100],
    dark: SKY[900],
    contrastText: WHITE
  },
  orange: {
    main: ORANGE[500],
    light: ORANGE[100],
    dark: ORANGE[900],
    contrastText: NAVY[900]
  },
  lemon: {
    main: LEMON[500],
    light: LEMON[100],
    dark: LEMON[900],
    contrastText: NAVY[900]
  },
  mango: {
    main: MANGO[500],
    light: MANGO[100],
    dark: MANGO[900],
    contrastText: WHITE
  },
  purple: {
    main: PURPLE[500],
    light: PURPLE[100],
    dark: PURPLE[900],
    contrastText: WHITE
  },
  emerald: {
    main: EMERALD[500],
    light: EMERALD[100],
    dark: EMERALD[900],
    contrastText: WHITE
  },
  deepBlue: {
    main: DEEP_BLUE[500],
    light: DEEP_BLUE[100],
    dark: DEEP_BLUE[900],
    contrastText: WHITE
  },
  olive: {
    main: OLIVE[500],
    light: OLIVE[100],
    dark: OLIVE[900],
    contrastText: WHITE
  },
  cyan: {
    main: CYAN[500],
    light: CYAN[100],
    dark: CYAN[900],
    contrastText: WHITE
  },
  teal: {
    main: TEAL[500],
    light: TEAL[100],
    dark: TEAL[900],
    contrastText: WHITE
  },
  grey: {
    ...GREY,
    main: GREY[500]
  }
};
