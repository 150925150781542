// We're using Intl.Numberformat in this file
import '../lib/numberFormatPolyfill';
import * as React from 'react';
import LinkBase, { LinkProps, AnchorProps, Anchor as AnchorBase } from './Link';
import { useRouter as useRouterBase, Router } from 'next/router';
import { UrlObject } from 'url';
import { Dictionary } from '@wooindex/common/types';
import useSWR from 'swr';

const LANG_PARAM_NAME = 'lang';
const HREF_PREFIX = `/[${LANG_PARAM_NAME}]`;

const DEFAULT_DICTIONARY: Dictionary = {
  technology: {},
  country: {},
  criterium: {},
  category: {}
};

const BYTES_PER_KB = 1024;
const BYTES_PER_MB = 1024 ** 2;
const BYTES_PER_GB = 1024 ** 3;

function createContextValue (localeIn?: string | null, dictionary: Dictionary = DEFAULT_DICTIONARY) {
  const locale = localeIn || 'en';
  const percent0 = new Intl.NumberFormat(locale, { style: 'percent', maximumFractionDigits: 0 });
  const percent1 = new Intl.NumberFormat(locale, { style: 'percent', maximumFractionDigits: 1 });
  const percent2 = new Intl.NumberFormat(locale, { style: 'percent', maximumFractionDigits: 2 });

  const byte = new Intl.NumberFormat(locale, { style: 'unit', unit: 'byte' });
  const kilobyte = new Intl.NumberFormat(locale, { style: 'unit', unit: 'kilobyte', maximumFractionDigits: 1 });
  const megabyte = new Intl.NumberFormat(locale, { style: 'unit', unit: 'megabyte', maximumFractionDigits: 1 });
  const gigabyte = new Intl.NumberFormat(locale, { style: 'unit', unit: 'gigabyte', maximumFractionDigits: 1 });

  return {
    language: locale,
    dateTimeFormat: {
      medium: new Intl.DateTimeFormat(locale, { dateStyle: 'medium', timeStyle: 'medium' }),
      mediumDate: new Intl.DateTimeFormat(locale, { dateStyle: 'medium' })
    },
    numberFormat: {
      basic: new Intl.NumberFormat(locale),
      score: new Intl.NumberFormat(locale, { maximumFractionDigits: 0 }),
      digital: {
        format: (value: number) => {
          value = Math.ceil(value);
          return value < BYTES_PER_KB
            ? byte.format(value)
            : value < BYTES_PER_MB
              ? kilobyte.format(value / BYTES_PER_KB)
              : value < BYTES_PER_GB
                ? megabyte.format(value / BYTES_PER_MB)
                : gigabyte.format(value / BYTES_PER_GB);
        }
      },
      percent: {
        format: (value: number) => value < 0.01 ? percent2.format(value) : value < 0.1 ? percent1.format(value) : percent0.format(value)
      },
      currency: {
        format: (value: number, currency?: string) => {
          // TODO: memoize formatter for euro/gbp/dollar?
          const formatter = new Intl.NumberFormat(locale, { minimumFractionDigits: 0, style: 'currency', currency });
          return formatter.format(value);
        }
      },
      compact: new Intl.NumberFormat(locale, { notation: 'compact', compactDisplay: 'short', maximumSignificantDigits: 3 })
    },
    dictionary
  };
}

const I18nContext = React.createContext(createContextValue());

interface I18nProviderProps {
  language: string | null
  children: React.ReactNode
  dictionary?: Dictionary
}

export function I18nProvider ({ language, children, ...props }: I18nProviderProps) {
  // loading dictionary from server if not provided
  const { data: dictionary } = useSWR<Dictionary>(props.dictionary ? null : '/api/dictionary', {
    dedupingInterval: 60 * 60 * 1000,
    initialData: props.dictionary
  });
  const ctxValue = React.useMemo(() => createContextValue(language, dictionary), [language, dictionary]);
  return (
    <I18nContext.Provider value={ctxValue}>
      {children}
    </I18nContext.Provider>
  );
}

export function useI18n () {
  return React.useContext(I18nContext);
}

type Url = string | UrlObject;

function localizeLink (language: string, href: Url, asPath: Url = href) {
  if (typeof href === 'string' && /^https?:\/\//.test(href)) {
    return { href };
  }
  return {
    href: typeof href === 'string'
      ? `${HREF_PREFIX}${href}`
      : { ...href, pathname: `${HREF_PREFIX}${href.pathname}` },
    as: typeof asPath === 'string'
      ? `/${language}${asPath}`
      : { ...asPath, pathname: `/${language}${asPath.pathname}` }
  };
}

export const Link = React.forwardRef<HTMLAnchorElement, LinkProps>(
  function Link ({ href, as, ...props }: LinkProps, ref) {
    const { language } = useI18n();
    const link = React.useMemo(() => localizeLink(language, href, as), [language, href, as]);
    return <LinkBase {...props} href={link.href} as={link.as} ref={ref} />;
  });

export const Anchor = React.forwardRef<HTMLAnchorElement, AnchorProps>(
  function Anchor ({ href, as, ...props }: AnchorProps, ref) {
    const { language } = useI18n();
    const link = React.useMemo(() => localizeLink(language, href, as), [language, href, as]);
    return <AnchorBase {...props} href={link.href} as={link.as} ref={ref} />;
  });

function removePrefix (value: string, prefix: string) {
  return value.startsWith(prefix) ? value.slice(prefix.length) : value;
}

export function useRouter () {
  const router = useRouterBase();
  const { language } = useI18n();

  const localizedRouter = React.useMemo(() => {
    const pathname = removePrefix(router.pathname, HREF_PREFIX);
    const asPathPrefix = `/${language}`;
    const asPath = removePrefix(router.asPath, asPathPrefix);
    const route = removePrefix(router.route, asPathPrefix);

    const push: typeof Router.prototype.push = (href, as, options) => {
      const link = localizeLink(language, href, as);
      return router.push(link.href, link.as, options);
    };

    const replace: typeof Router.prototype.replace = (href, as, options) => {
      const link = localizeLink(language, href, as);
      return router.replace(link.href, link.as, options);
    };

    const beforePopState: typeof Router.prototype.beforePopState = cb => {
      return router.beforePopState(cb);
    };

    const prefetch: typeof Router.prototype.prefetch = url => {
      return router.prefetch(`${HREF_PREFIX}${url}`);
    };

    return {
      pathname,
      locale: language,
      asPath,
      query: router.query,
      isReady: router.isReady,
      isFallback: router.isFallback,
      route,
      push,
      back: () => router.back(),
      replace,
      reload: () => router.reload(),
      beforePopState,
      prefetch
    };
  }, [
    router,
    language
  ]);

  return localizedRouter;
}
