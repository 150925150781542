import * as React from 'react';
import NextLink, { LinkProps as NextLinkProps } from 'next/link';
import { Link as MuiLink, LinkProps as MuiLinkProps } from '@material-ui/core';

export type AnchorProps = Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, 'href'> & NextLinkProps;

// an <a> wrapped by a next/link. Intended to be composed in a @material-ui/core/Link
export const Anchor = React.forwardRef(function Anchor (props: AnchorProps, ref: React.ForwardedRef<HTMLAnchorElement>) {
  const { as, href, replace, scroll, passHref, shallow, prefetch, ...other } = props;

  return (
    <NextLink
      href={href}
      prefetch={prefetch}
      as={as}
      replace={replace}
      scroll={scroll}
      shallow={shallow}
      passHref={passHref}
    >
      <a ref={ref} {...other} />
    </NextLink>
  );
});

interface LinkPropsBase {
  innerRef?: React.Ref<HTMLAnchorElement>;
}

export type LinkProps = LinkPropsBase & AnchorProps & Omit<MuiLinkProps, 'href'>;

// A styled version of the Next.js Link component:
// https://nextjs.org/docs/#with-link
export default React.forwardRef(function Link (props: LinkProps, ref: React.ForwardedRef<HTMLAnchorElement>) {
  const {
    href,
    ...other
  } = props;

  return <MuiLink component={Anchor} ref={ref} href={href as string} {...other} />;
});
