import { createTheme, lighten } from '@material-ui/core';
import {
  PURPLE,
  DEEP_BLUE,
  EMERALD,
  GREEN,
  YELLOW,
  GREY,
  RED,
  WHITE
} from '../../common/src/theme/colors';

declare module '@material-ui/core/styles/createPalette' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface Palette {
    footer: Palette['primary'];
    accent: Palette['primary'];
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface PaletteOptions {
    footer: PaletteOptions['primary'];
    accent: PaletteOptions['primary'];
  }
}

const PRIMARY = {
  main: DEEP_BLUE[800],
  light: DEEP_BLUE[300],
  dark: DEEP_BLUE[900],
  contrastText: WHITE
};

const theme = createTheme({
  typography: {
    fontFamily: [
      '"Open Sans"',
      'Helvetica',
      'Arial',
      'sans-serif'
    ].join(',')
  },
  props: {
    MuiButton: {
      variant: 'outlined',
      size: 'small',
      disableElevation: true
    }
  },
  palette: {
    type: 'light',
    background: {
      default: GREY[100]
    },
    primary: PRIMARY,
    secondary: {
      main: EMERALD[500],
      light: EMERALD[300],
      dark: EMERALD[900],
      contrastText: WHITE
    },
    accent: {
      light: PURPLE[300],
      main: PURPLE[500],
      dark: PURPLE[900],
      contrastText: WHITE
    },
    success: {
      light: GREEN[200],
      main: GREEN[300],
      dark: GREEN[900]
    },
    warning: {
      light: YELLOW[200],
      main: YELLOW[300],
      dark: YELLOW[900]
    },
    error: {
      light: RED[200],
      main: RED[300],
      dark: RED[900]
    },
    footer: {
      main: GREY[100],
      contrastText: GREY[500]
    },
    action: {
      hover: lighten(PRIMARY.light, 0.9)
    }
  },
  shape: {
    borderRadius: 10
  }
});

theme.overrides = {
  MuiButton: {
    root: {
      textTransform: 'none'
    },
    sizeSmall: {
      borderRadius: 7
    }
  },
  MuiTableCell: {
    head: {
      ...theme.typography.overline,
      color: GREY[500],
      fontWeight: theme.typography.fontWeightBold
    }
  },
  MuiDialogActions: {
    root: {
      padding: theme.spacing(1, 3, 2, 3)
    }
  }
};

export default theme;
