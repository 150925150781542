"use strict";
/* global fetch Request Headers */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.del = exports.head = exports.patch = exports.put = exports.post = exports.get = exports.createApiResponse = exports.TimeoutError = exports.HTTPError = void 0;
var promises_1 = require("./promises");
var BACKOFF_FACTOR = 2.2;
var HTTPError = /** @class */ (function (_super) {
    __extends(HTTPError, _super);
    function HTTPError(response) {
        var _this = _super.call(this, "Fetch error " + response.status + ": " + response.statusText) || this;
        // NOTE: See https://github.com/Microsoft/TypeScript/wiki/FAQ#why-doesnt-extending-built-ins-like-error-array-and-map-work
        Object.setPrototypeOf(_this, HTTPError.prototype);
        _this._response = response;
        return _this;
    }
    Object.defineProperty(HTTPError.prototype, "response", {
        get: function () {
            return this._response;
        },
        enumerable: false,
        configurable: true
    });
    return HTTPError;
}(Error));
exports.HTTPError = HTTPError;
var TimeoutError = /** @class */ (function (_super) {
    __extends(TimeoutError, _super);
    function TimeoutError(info, timeout) {
        var _this = this;
        var url = typeof info === 'string' ? info : info.url;
        _this = _super.call(this, "Request to " + url + "\" timed out after " + timeout + "ms") || this;
        return _this;
    }
    return TimeoutError;
}(Error));
exports.TimeoutError = TimeoutError;
// Fetches a response with retries and HTTP error handling
function fetchResponse(input, _a) {
    var _b = _a.throwHttpErrors, throwHttpErrors = _b === void 0 ? true : _b, options = __rest(_a, ["throwHttpErrors"]);
    return __awaiter(this, void 0, void 0, function () {
        var availableAttempts, attempts, backoffInterval, response, err_1;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    availableAttempts = typeof options.retry !== 'undefined'
                        ? (options.retry + 1)
                        : (options.method === 'GET' || options.method === 'HEAD' ? 3 : 1);
                    attempts = 0;
                    backoffInterval = 1000;
                    _c.label = 1;
                case 1:
                    if (!true) return [3 /*break*/, 7];
                    _c.label = 2;
                case 2:
                    _c.trys.push([2, 4, , 6]);
                    attempts += 1;
                    return [4 /*yield*/, fetch(input, options)];
                case 3:
                    response = _c.sent();
                    if (throwHttpErrors && !response.ok) {
                        throw new HTTPError(response);
                    }
                    return [2 /*return*/, response];
                case 4:
                    err_1 = _c.sent();
                    if (err_1 instanceof HTTPError) {
                        throw err_1;
                    }
                    if (attempts >= availableAttempts) {
                        throw err_1;
                    }
                    // back off for a moment, then retry
                    return [4 /*yield*/, promises_1.delay(backoffInterval)];
                case 5:
                    // back off for a moment, then retry
                    _c.sent();
                    backoffInterval *= BACKOFF_FACTOR;
                    return [3 /*break*/, 6];
                case 6: return [3 /*break*/, 1];
                case 7: return [2 /*return*/];
            }
        });
    });
}
function createApiResponse(response) {
    var _this = this;
    var responsePromise = Promise.resolve(response);
    return Object.assign(responsePromise, {
        // shorthand for .json()
        json: function () { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, responsePromise];
                case 1: return [2 /*return*/, (_a.sent()).json()];
            }
        }); }); },
        // shorthand for .text()
        text: function () { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, responsePromise];
                case 1: return [2 /*return*/, (_a.sent()).text()];
            }
        }); }); },
        // shorthand for .blob()
        blob: function () { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, responsePromise];
                case 1: return [2 /*return*/, (_a.sent()).blob()];
            }
        }); }); },
        // shorthand for .buffer()
        // @ts-expect-error Buffer exists in node.js only
        buffer: function () { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, responsePromise];
                case 1: return [2 /*return*/, (_a.sent()).buffer()];
            }
        }); }); }
    });
}
exports.createApiResponse = createApiResponse;
function createMethod(method) {
    return function doFetch(input, options) {
        var e_1, _a, e_2, _b;
        if (options === void 0) { options = {}; }
        options.method = method;
        options.headers = new Headers(options.headers);
        var searchParams = options.searchParams, json = options.json, 
        // will add a default timeout of 10000 later
        timeout = options.timeout;
        var fetchInput;
        if (input instanceof Request) {
            fetchInput = input;
        }
        else {
            var fetchUrl = new URL(input, typeof window === 'undefined' ? undefined : window.location.href);
            if (searchParams) {
                try {
                    for (var _c = __values(fetchUrl.searchParams.keys()), _d = _c.next(); !_d.done; _d = _c.next()) {
                        var key = _d.value;
                        fetchUrl.searchParams.delete(key);
                    }
                }
                catch (e_1_1) { e_1 = { error: e_1_1 }; }
                finally {
                    try {
                        if (_d && !_d.done && (_a = _c.return)) _a.call(_c);
                    }
                    finally { if (e_1) throw e_1.error; }
                }
                var newParams = new URLSearchParams(searchParams);
                try {
                    for (var _e = __values(newParams.entries()), _f = _e.next(); !_f.done; _f = _e.next()) {
                        var _g = __read(_f.value, 2), key = _g[0], value = _g[1];
                        fetchUrl.searchParams.append(key, value);
                    }
                }
                catch (e_2_1) { e_2 = { error: e_2_1 }; }
                finally {
                    try {
                        if (_f && !_f.done && (_b = _e.return)) _b.call(_e);
                    }
                    finally { if (e_2) throw e_2.error; }
                }
            }
            fetchInput = fetchUrl.toString();
        }
        if (json) {
            if (options.body) {
                throw new Error('can\'t specify json and body at the same time');
            }
            options.headers.append('content-type', 'application/json');
            options.body = JSON.stringify(json);
        }
        else if (options.body instanceof URLSearchParams) {
            options.headers.append('content-type', 'application/x-www-form-urlencoded');
            options.body = String(options.body);
        }
        var promises = [
            fetchResponse(fetchInput, __assign({ 
                // default by spec, but not in all browsers yet:
                credentials: 'same-origin' }, options))
        ];
        if (timeout) {
            // naive timeout logic, will improve with abort logic in the future
            promises.push(new Promise(function (resolve, reject) {
                setTimeout(function () { return reject(new TimeoutError(fetchInput, timeout)); }, timeout);
            }));
        }
        var responsePromise = Promise.race(promises);
        return createApiResponse(responsePromise);
    };
}
exports.get = createMethod('GET');
exports.post = createMethod('POST');
exports.put = createMethod('PUT');
exports.patch = createMethod('PATCH');
exports.head = createMethod('HEAD');
exports.del = createMethod('DELETE');
