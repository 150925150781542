import * as React from 'react';
import { omit } from '@wooindex/common/collections';

const STORAGE_KEY = 'feature-flags';

type Flags = Partial<{
  saveTodrive: boolean
  sticky: boolean
}>

declare global {
  interface Window {
    enableFeature?: (flag: keyof Flags) => void
    disableFeature?: (flag: keyof Flags) => void
  }
}

const FeatureFlagContext = React.createContext<Flags>({});

type FeatureFlagProviderProps = React.PropsWithChildren<{}>

function getFeatureFlags (): Flags {
  try {
    const content = window.localStorage.getItem(STORAGE_KEY);
    return content ? JSON.parse(content) as Flags : {};
  } catch (err) {
    return {};
  }
}

export function FeatureFlagProvider ({ children }: FeatureFlagProviderProps) {
  const [flags, setFlags] = React.useState<Flags>({});
  React.useEffect(() => {
    setFlags(getFeatureFlags());
    window.enableFeature = (flag: keyof Flags) => {
      const flags = getFeatureFlags();
      window.localStorage.setItem(STORAGE_KEY, JSON.stringify({
        ...flags,
        [flag]: true
      }));
    };
    window.disableFeature = (flag: keyof Flags) => {
      const flags = omit(getFeatureFlags(), flag);
      window.localStorage.setItem(STORAGE_KEY, JSON.stringify(flags));
    };
    return () => {
      delete window.enableFeature;
      delete window.disableFeature;
    };
  }, []);
  return (
    <FeatureFlagContext.Provider value={flags}>
      {children}
    </FeatureFlagContext.Provider>
  );
}

export function useHasFeature (flag: keyof Flags): boolean {
  const { [flag]: hasFeature = false } = React.useContext(FeatureFlagContext);
  return hasFeature;
}
