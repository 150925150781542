import { UpdateUserInput, User } from '@wooindex/common/types';
import * as React from 'react';
import * as fetcher from '@wooindex/common/fetcher';

interface UserContextValue {
  user: User | null
  loading: boolean
  /**
   * Refresh databravo user from the server.
   */
  refresh: () => Promise<void>,
  /**
   * Fully resync external connections between databravo user and Paddle/auth0.
   * Use with caution.
   */
  resync: () => Promise<void>,
  update: (input: UpdateUserInput) => Promise<void>,
  logOut: () => void
}

const UserContext = React.createContext<UserContextValue>({
  user: null,
  loading: false,
  refresh: async () => {},
  resync: async () => {},
  update: async () => {},
  logOut: () => {}
});

async function silentLogin (): Promise<User | null> {
  try {
    return await fetcher.get('/api/auth/login?silent=1&redirectTo=/api/user', { mode: 'no-cors' }).json() as User;
  } catch {
    return null;
  }
}

async function fetchUser (): Promise<User | null> {
  try {
    return await fetcher.get('/api/user').json() as User;
  } catch (err) {
    const isLoggedOut = err instanceof fetcher.HTTPError && err.response.status === 401;
    if (isLoggedOut) {
      return null;
    }
    throw err;
  }
}

export function UserProvider ({ children, user: initialUserIn }: React.PropsWithChildren<{ user?: User | null }>) {
  const initialUser = React.useRef(initialUserIn);
  const isUnmounted = React.useRef(false);
  const pending = React.useRef<Promise<void> | null>(null);
  const [loading, setLoading] = React.useState(!initialUser);
  const [user, setUser] = React.useState<User | null>(initialUser.current || null);

  const refresh = React.useCallback(async (trySilentLogin?: boolean) => {
    if (!pending.current) {
      pending.current = (async () => {
        try {
          setLoading(true);
          let user = await fetchUser();
          if (!user && trySilentLogin) {
            user = await silentLogin();
          }
          if (!isUnmounted.current) {
            setUser(user);
          }
        } catch (err) {
          console.error(err);
        } finally {
          setLoading(false);
        }
      })();
    }

    await pending.current;
    pending.current = null;
  }, []);

  const resync = React.useCallback(async () => {
    setLoading(true);
    await fetcher.post('/api/user');
    await refresh();
  }, [refresh]);

  const logOut = React.useCallback(() => {
    window.location.href = '/api/auth/logout';
  }, []);

  const update = React.useCallback(async (input: UpdateUserInput) => {
    const user = await fetcher.put('/api/user', {
      json: input
    }).json() as User;
    setUser(user);
  }, []);

  React.useEffect(() => {
    if (!initialUser.current) {
      refresh(true);
    }

    return () => {
      isUnmounted.current = true;
    };
  }, [refresh]);

  const contextValue = React.useMemo(() => ({
    user, loading, refresh, resync, logOut, update
  }), [user, loading, refresh, resync, logOut, update]);

  return (
    <UserContext.Provider value={contextValue}>
      {children}
    </UserContext.Provider>
  );
}

export function useUser (): UserContextValue {
  return React.useContext(UserContext);
}
